import React, { useState, useEffect } from 'react';
import '../style/cr2.css';

export default function CarPhotoDesc({ props }) {
    const [imgUrl, setImgUrl] = useState(
        // 'http://www.zccindia.com/wp-content/uploads/2015/05/exterior-1003-big.jpg'
        ''
    );
    // console.log(datas)
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { car, versionCount, colorCount } = props.state;

    const datas =
        car.varients[versionCount].products[colorCount].interior_images;

    useEffect(() => {
        try {
            setImgUrl(
                'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
                    datas[0].interior_images
            );
            setTitle(datas[0].title);
            setDescription(datas[0].int_description);
        } catch (err) {
            console.log(err);
        }
    }, []);


    // const handelClick = (e) => {
    //     console.log(e.target.src);
    //     setImgUrl(e.target.src);
    //     datas.forEach((data) => {
    //         if (
    //             imgUrl ===
    //             'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
    //                 data.interior_images
    //         ) {
    //             setTitle(data.title);
    //             setDescription(data.int_description);
    //         }
    //     });
    // };
    if (datas.length > 0)
        return (
            <div className="cr2_pd_a">
                <div className="cr_1e">Interior design</div>
                <div className="cr2_pd_b">
                    <div className="cr2_pd_g">
                        {datas.map((item, k) => (
                            <img
                                key={k}
                                alt="sfsd"
                                src={
                                    'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
                                    item.interior_images
                                }
                                className="cr2_pd_h"
                                onClick={() => [
                                    setTitle(item.title),
                                    setImgUrl(
                                        'https://kalyanimotorsadmin.kalyanicrm.com/storage/' +
                                            item.interior_images
                                    ),
                                    setDescription(item.int_description),
                                ]}
                            />
                        ))}
                    </div>
                    <img alt="fsdf" className="cr2_pd_c" src={imgUrl} />
                    <div className="cr2_pd_d">
                        <div className="cr2_pd_e">{title}</div>
                        <div className="cr2_pd_f">{description}</div>
                    </div>
                </div>
            </div>
        );
    else return null;
}
