import React , { useEffect }  from "react";
import { appStore, kalyaniLight, playStore } from "../module/logo";

import btmCar from "../asset/btm_car.png";
import btmWhatsapp from "../asset/btm_whatsapp.png";
import btmTele from "../asset/btm_tele.png";
import btmContact from "../asset/btm_contact.png";

import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga'

ReactGA.initialize("UA-33586910-1");
ReactGA.initialize("AW-1014248716");


function HomeFooter({ props }) {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const ReactPixel =  require('react-facebook-pixel');
    ReactPixel.default.init('539229427481212');
  });


  const { location, dynamic_links } = props.state;

  const loc_id = location != null ? location.id : "0";

  const homeData = [
    {
      title: "Home",
      route: "/",
      fun: () => (window.location = "/"),
    },
  ];

  for (let i = 0; i < dynamic_links.length; i++) {
    homeData.push({
      title: dynamic_links[i]["title"],
      route: "/dynamic/" + dynamic_links[i]["title"],
      fun: () => (window.location = "/dynamic/" + dynamic_links[i]["title"]),
    });
  }

  for (let i = 0; i < dynamic_links.length; i++) {
    // homeData.push({
    //   title: dynamic_links[i]["title"],
    //   route: "/dynamic/" + dynamic_links[i]["title"],
    //   fun: () => (window.location = "/dynamic/" + dynamic_links[i]["title"]),
    // });
  }

  const title = [
    "HOME",
    "NEW CARS",
    // "ONLINE BOOKING",
    "ABOUT US",
    "OUR SERVICES",
  ];
  const data = [
    homeData,
    [
      {
        title: "Arena Cars",
        route: null,
        fun: () => (window.location = "/allcars/" + loc_id + "/arena"),
      },
      {
        title: "Nexa Cars",
        route: null,
        fun: () => (window.location = "/allcars/" + loc_id + "/nexa"),
      },
      {
        title: "Commercial Cars",
        route: null,
        fun: () => (window.location = "/allcars/" + loc_id + "/commercial"),
      },
      // {
      //   title: "Dream Cars",
      //   route: null,
      //   fun: () => (window.location = "/allcars/" + loc_id + "/dreamcars"),
      // },
      // {
      //   title: "Used Cars",
      //   route: null,
      //   fun: () => {},
      // },
    ],
    // [
    //   {
    //     title: "Service Booking",
    //     route: null,
    //     fun: () => props.setState({ enquiryPopup: { car: "", type: 8 } }),
    //   },
    //   {
    //     title: "Test Drive Booking",
    //     route: null,
    //     fun: () => props.setState({ enquiryPopup: { car: "", type: 3 } }),
    //   },
    // ],
    [
      {
        title: "Contact Us",
        route: "/contactus",
        fun: () => (window.location = "/contactus"),
      },
      {
        title: "About Kalyani",
        route: "/about/about",
        fun: () => (window.location = "/about/about"),
      },
      {
        title: "Why Kalyani Motors",
        route: "/about/whykalyani",
        fun: () => (window.location = "/about/whykalyani"),
      },
      {
        title: "Quality Policy",
        route: "/about/qualitypolicy",
        fun: () => (window.location = "/about/qualitypolicy"),
      },
      {
        title: "Awards And Achievements",
        route: "/about/awards",
        fun: () => (window.location = "/about/awards"),
      },
      {
        title: "Maruti Driving School",
        route: null,
        fun: () => props.setState({ enquiryPopup: { car: "", type: 11 } }),
      },
      // {
      //   title: "Finance Junction",
      //   route: null,
      //   fun: () => {},
      // },
      {
        title: "Gallery",
        route: "/about/gallery",
        fun: () => (window.location = "/about/gallery"),
      },
    ],
    [
      {
        title: "Sales",
        route: "/allcars",
        fun: () => (window.location = "/allcars"),
      },
      {
        title: "Service",
        route: "/service",
        fun: () => (window.location = "/service"),
      },
      {
        title: "True Value",
        route: null,
        fun: () => {},
      },
      {
        title: "Insurance",
        route: null,
        fun: () => props.setState({ enquiryPopup: { car: "", type: 9 } }),
      },
      {
        title: "Careers At Kalyani",
        route: null,
        fun: () => props.setState({ enquiryPopup: { car: "", type: 10 } }),
      },
      // {
      //   title: "Sales Commercial",
      //   route: null,
      //   fun: () => {},
      // },
      // {
      //   title: "Corparate Office",
      //   route: null,
      //   fun: () => {},
      // },
    ],
  ];

  const footerStyle = (r) =>
    r === window.location.pathname ? { color: "yellow" } : {};

  return (
    <React.StrictMode>
      <div className="footer_top" id="hm_g">
        {data.map((datas, k) => (
          <div key={k} className="footer_top_each">
            <div className="footer_top_title">{title[k]}</div>
            {datas.map((item, j) => (
              <div
                key={j}
                style={footerStyle(item.route)}
                onClick={item.fun}
                className="footer_top_button"
              >
                {item.title}
              </div>
            ))}
          </div>
        ))}
      </div>
      {location != null ? (
        <div className="footer_down">
          <div>
            <div className="footer_logo_add">
              <img className="footer_logo" src={kalyaniLight} alt="Kalyani" />
              <div className="footer_address">{location.address}</div>
            </div>
            <div className="footer_socialmedia_icon">
              <div
                onClick={() => (window.location = location.facebook_id)}
                className="footer_contact_icon_fb"
              />
              <div
                onClick={() => (window.location = location.twitter_id)}
                className="footer_contact_icon_twitter"
              />
              <div
                onClick={() => (window.location = location.instagram_id)}
                className="footer_contact_icon_instagram"
              />
              <div
                onClick={() => (window.location = location.whatsapp)}
                className="footer_contact_icon_whatsapp"
              />
              <div
                onClick={() => (window.location = location.youtube_id)}
                className="footer_contact_icon_youtube"
              />
            </div>
          </div>
          <div className="footer_contact">
            <div className="footer_contact_each">
              <div className="footer_contact_icon_email" />
              <div className="footer_contact_title">Email</div>
              <div className="footer_contact_data">{location.email}</div>
            </div>
            <div className="footer_contact_each">
              <div className="footer_contact_icon_phone" />
              <div className="footer_contact_title">Phone Number</div>
              <div className="footer_contact_data">{location.phone_number}</div>
            </div>
            {/* <div className="footer_contact_each">
              <div className="footer_contact_icon_callender" />
              <div className="footer_contact_title">Working Days</div>
              <div className="footer_contact_data">{location.working_days}</div>
            </div>
            <div className="footer_contact_each">
              <div className="footer_contact_icon_clock" />
              <div className="footer_contact_title">Working Hours</div>
              <div className="footer_contact_data">
                {location.working_hours}
              </div>
            </div> */}
          </div>
          <div className="footer_applink">
            <img width="150" alt="img" src={playStore} />
            <br />
            <img width="150" alt="img" src={appStore} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="footer_copyright">
        COPYRIGHT © 2022 KALYANI MOTORS PVT LTD, BANGALORE, INDIA | Designed by:
        Kalyani Motors
      </div>
      <div className="hm3_sf_a">
        <div
          className="hm3_sf_b"
          onClick={() => (window.location = "/allcars/" + location.id ?? 0)}
        >
          <img alt="i" className="hm3_sf_c" src={btmCar} />
          Cars
        </div>
        <div
          className="hm3_sf_b"
          onClick={() =>
            window.location.assign("https://wa.me/+91" + location.phone_number)
          }
        >
          <img alt="i" className="hm3_sf_c" src={btmWhatsapp} />
          Whatsapp
        </div>
        <div
          className="hm3_sf_b"
          onClick={() =>
            window.location.assign("tel:" + +location.phone_number)
          }
        >
          <img alt="i" className="hm3_sf_c" src={btmTele} />
          Call
        </div>
        <div
          className="hm3_sf_b"
          onClick={() => (window.location = "/contactus")}
        >
          <img alt="i" className="hm3_sf_c" src={btmContact} />
          Contact Us
        </div>
        <div className="hm3_sf_b"></div>
      </div>
    </React.StrictMode>
  );
}
export default HomeFooter;
