import React, { useEffect, useState } from 'react';
import checkAnim from '../asset/checkAnim.gif';
import tickIcon from '../asset/check.JPG';
import '../style/hm3.css';
import axios from 'axios';
import enquiry from '../method/enquiry';
import { getImage } from '../module/api_init';

function HomeOnroudPrice({ props }) {
    const [carName, setCarName] = useState([]);
    const [booked, setbooked] = useState(0);
    const [bike, setbike] = useState('');

    useEffect(() => {
        axios
            .get('http://kalyanimotorsapi.kalyanicrm.com/api/allmodellist')
            .then((response) => {
                console.log(response);
                setCarName(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const { seasonCars, bannar_e } = props.state;
    // console.log(seasonCars);

    return (
        <div className="center">
            <div
                className="hm3_Container2"
                style={{ backgroundImage: 'url(' + getImage(bannar_e) + ')' }}
            >
                <div className="hm3_left-div">
                    {booked === 0 ? (
                        <>
                            <div className="all1_hm_title">On-Road Price</div>
                            <div
                                style={{
                                    fontSize: 11,
                                    color: 'gray',
                                    padding: '0px 10px',
                                }}
                            >
                                Kindly fill your details to get Maruti Suzuki
                                car on-road price from our sales expert.
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {booked !== 0 ? (
                        booked === 1 ? (
                            <img
                                alt="Img"
                                style={{ padding: '20px 103px', width: 100 }}
                                src={checkAnim}
                            />
                        ) : (
                            <img
                                alt="Img"
                                style={{ padding: '20px 103px', width: 100 }}
                                src={tickIcon}
                            />
                        )
                    ) : (
                        <form
                            id="booking_form1"
                            onSubmit={async (e) => {
                                if (e.target.id.value.length !== 10) {
                                    alert('Not a valid phone number');
                                    return;
                                }
                                setbooked(1);
                                setbike(e.target.car.value);
                                const body = {
                                    name: e.target.name.value,
                                    phone: e.target.id.value,
                                    // email: "",
                                    // location: "",
                                    // vehicle: e.target.car.value,
                                    // purpose: "On Road Price",
                                    purpose: e.target.car.value,
                                };
                                await enquiry(body);
                                setTimeout(() => setbooked(2), 1700);
                            }}
                        >
                            <input
                                placeholder="Enter Your Name"
                                className="hm3_input_tab"
                                type="text"
                                id="name"
                                required
                            />
                            <input
                                placeholder="Enter Your Phone Number"
                                className="hm3_input_tab"
                                type="text"
                                id="id"
                                required
                            />
                            <select className="hm3_input_tab" id="car" required>
                                <option>Choose Your Car</option>
                                {carName.map((car, k) => (
                                    <option value={car.title} key={k}>
                                        {car.title}
                                    </option>
                                ))}
                            </select>
                            <button type="submit" className="hm3_btn">
                                Check On-Road Price
                            </button>
                        </form>
                    )}
                    {booked !== 0 ? (
                        <p
                            style={{
                                padding: '0px 4px',
                                fontSize: 14,
                                color: '#4d5057',
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            Thank you for your interest in {bike} We will get in
                            touch with you soon...
                        </p>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}

export default HomeOnroudPrice;