import axios from "axios";
import { makelinespace } from "../module/simple";

export async function getAllcars(props) {
  const url = "https://kalyanimotorsapi.kalyanicrm.com/api/";
  await Promise.all([
    axios.get(url + "model"),
    axios.get(url + "category"),
    axios.get(url + "nexa"),
    axios.get(url + "commercial"),
    axios.get(url + "iconlocation"),
    axios.get(url + "blogpage"), //  4
  ])
    .then((res) => {
      console.log(res[0].data);

      // /////////////////////////////////////////////////////////////////
      var locations_h = [];
      var locations_b = [];
      var locations_c = [];
      var locations_r = [];
      for (let i = 0; i < res[4].data.length; i++) {
        const element = res[4].data[i];
        if (element.city === 1) locations_b.push(element);
        else locations_h.push(element);
      }

      var location = localStorage.getItem("location");
      if (location === "Hydrabad" && locations_h.length > 0)
        location = locations_h[0];
      if (location === "Channapatna" && locations_c.length > 0)
        location = locations_h[0];
      if (location === "Ramanagara" && locations_r.length > 0)
        location = locations_h[0];
      if (location === "Bengaluru" && locations_b.length > 0)
        location = locations_h[0];

      const brands = [{ title: "All", models: res[0].data }].concat(
        res[1].data
      );
      props.setState({
        brands,
        locations_h,
        locations_b,
        location,
        dynamic_links: res[4].data,
      });
    })
    .catch(() => props.setState({ error: "Not Fount" }));

  const path = window.location.pathname.split("/")[3];
  const pathModel = window.location.pathname.split("/")[4];

  if (path === "nexa") props.setState({ brand: 1 });
  if (path === "arena") props.setState({ brand: 2 });
  if (path === "commercial") props.setState({ brand: 3 });

  if (pathModel != null) {
    await ontapModel(props, makelinespace(pathModel));
    props.setState({ selectedmodel: makelinespace(pathModel) });
  }
  props.setState({ loading: false });
  return 0;
}

export async function ontapModel(props, model) {
  props.setState({ loading: true });
  const url =
    "https://kalyanimotorsapi.kalyanicrm.com/api/varientbike?name=" + model;
  await axios
    .get(url)
    .then((res) => {
      props.setState({ items: res.data, selectedmodel: model });
    })
    .catch(() => props.setState({ error: "Not Fount" }));
  props.setState({ loading: false });
}
