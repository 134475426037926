import React from "react";
import { getFile } from "../module/api_init";
import { getComaPrice } from "../module/simple";
import "../style/cr2.css";

function CarPrice({ props }) {
  const { car } = props.state;
  return (
    <div id="price" className="center">
      <div className="cr2_a">
        <div className="cr_1e">&nbsp; {car.title} Price</div>
        <div className="cr2_ac">
          <div className="cr2_aca" style={{ width: "40%  " }}>
            Variant
          </div>
          <div className="cr2_aca">Price</div>
          <div className="cr2_aca"></div>
        </div>
        <div className="cr2_aa" />
        {car.varients.map((item, k) => (
          <React.StrictMode key={k}>
            <div className="cr2_ac">
              <div className="cr2_ada">{item.var_title}</div>
              <div className="cr2_adb">
                ₹ {getComaPrice(item.products[0].price)} /-
                <div className="cr2_adba">Avg. Ex-showroom price</div>
              </div>
              <div className="cr2_adc">
                <div
                  className="cr2_adca"
                  onClick={() => window.open(getFile(car.file), "_blank")}
                >
                  Download Brochure
                </div>
                {/* <div className="bk1_dt1_cbiab" onClick={() => setenquiry(0)}>
                      Get On-road Price
                    </div> */}
              </div>
            </div>
            {item.length - 1 !== k ? <div className="cr2_aa" /> : ""}
          </React.StrictMode>
        ))}
      </div>
    </div>
  );
}

export default CarPrice;
