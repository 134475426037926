import axios from "axios";

export async function getdataforabout(props) {
  await axios
    .get("https://kalyanimotorsapi.kalyanicrm.com/api/iconlocation")
    .then((res) => {
      var locations_h = [];
      var locations_b = [];
      var locations_c = [];
      var locations_r = [];
      for (let i = 0; i < res.data.length; i++) {
        const element = res.data[i];
        if (element.city === 1) locations_b.push(element);
        else locations_h.push(element);
      }

      var location = localStorage.getItem("location");
      if (location === "Hydrabad" && locations_h.length > 0)
        location = locations_h[0];
      if (location === "Channapatna" && locations_c.length > 0)
        location = locations_h[0];
      if (location === "Ramanagara" && locations_r.length > 0)
        location = locations_h[0];
      if (location === "Bengaluru" && locations_b.length > 0)
        location = locations_h[0];
      props.setState({
        locations_h,
        locations_b,
        location,
      });
    })
    .catch(() => props.setState({ error: "Error on loading." }));
  await axios
    .get("https://kalyanimotorsapi.kalyanicrm.com/api/blogpage")
    .then((res) => props.setState({ dynamic_links: res.data }))
    .catch(() => props.setState({ error: "Not Fount" }));
  props.setState({ loading: false });
}
