import React from "react";
import { kalyaniDark } from "../module/logo";
// import arrow_down from "../asset/arrow_down.svg";
import close_1 from "../asset/close_1.svg";
import { getImage } from "../module/api_init";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import phoneIcon from "../asset/phone_icon.png";
import maruthiLogo from "../asset/marutiLogo2.png";
import maruthiLogo2 from "../asset/maruthiLogo.png";

export function HomeLocationPopup({ props }) {
  const { locations_h, locations_b, locationPopup } = props.state;
  return (
    <div
      style={locationPopup ? { visibility: "visible" } : {}}
      className="mylocation_popup_screen"
    >
      <div className="mylocation_popup">
        <div className="row">
          <div style={{ width: "100%" }}>
            <div className="mylocation_popup_title">Choose Your City</div>
            <div className="mylocation_popup_subtitle">
              This would help us in providing you quick service.
            </div>
          </div>
          <img
            width="20"
            alt="img"
            style={{ cursor: "pointer" }}
            onClick={() => props.setState({ locationPopup: false })}
            src={close_1}
          />
        </div>
        <div className="mylocation_popup_subtitle2">Bangalore</div>
        <div className="mylocation_popup_body">
          {locations_b.map((item, k) => (
            <div
              key={k}
              className="mylocation_each"
              onClick={() =>
                props.setState({ location: item, locationPopup: false })
              }
            >
              <img width="50" src={getImage(item.loc_image)} alt="img"  />
              
              <div>{item.title}</div>
            </div>
          ))}
        </div>
        <div className="mylocation_popup_subtitle2">Hydrabad</div>
        <div className="mylocation_popup_body">
          {locations_h.map((item, k) => (
            <div
              key={k}
              className="mylocation_each"
              onClick={() =>
                props.setState({ location: item, locationPopup: false })
              }
            >
              <img width="50" src={getImage(item.loc_image)} alt="img" />
              <div>{item.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function Header({ props }) {
  const { location } = props.state;
  const { locations_h, locations_b, locations_c, locations_r } = props.state;
  // if (location.city != null)
  //   locName =
  //     (location.city === 1 ? "Bengaluru" : "Hydrabad") +
  //     " - " +
  //     location.title +
  //     " ";
  return (
    <React.StrictMode>
      <div className="hm1_hd_a">
        <div className="home_base_logo_area">
          <img
            loading="lazy"
            src={kalyaniDark}
            className="home_base_logo"
            onClick={() => (window.location = "/")}
            alt="KAlyani"
          />
          <select
            className="location_text"
            value={localStorage.getItem("location")}
            onChange={(e) => {
              var location = e.target.value;
              if (location === "Hydrabad" && locations_h.length > 0) {
                props.setState({ location: locations_h[0] });
                localStorage.setItem("location", "Hydrabad");
              }
              // if (location === "Channapatna" && locations_c.length > 0) {
              //   props.setState({ location: locations_c[0] });
              //   localStorage.setItem("location", "Channapatna");
              // }
              // if (location === "Ramanagara" && locations_r.length > 0) {
              //   props.setState({ location: locations_r[0] });
              //   localStorage.setItem("location", "Ramanagara");
              // }
              if (location === "Bengaluru" && locations_b.length > 0) {
                props.setState({ location: locations_b[0] });
                localStorage.setItem("location", "Bengaluru");
              }
            }}
          >
            <option value="Bengaluru">Bengaluru</option>
            {/* <option value="Channapatna">Channapatna</option>
            <option value="Ramanagara">Ramanagara</option> */}
            <option value="Hydrabad">Hyderabad</option>
          </select>
          {/* <div
            onClick={() => props.setState({ locationPopup: true })}
            className="location_text"
          >
            {locName}
            <img alt="img" loading="lazy" src={arrow_down} />
          </div> */}
        </div>
        <div className="header_container_menu">
          <div
            className="header_text"
            onClick={() =>
              window.location.assign("tel:" + (location.phone_number ?? ""))
            }
          >
            <img alt="Img" src={phoneIcon} className="headphone_icon" />

            {location != null ? location.phone_number : null}
          </div>
          <img width="45" alt="Maruthi Logo" src={maruthiLogo} className="logo_icon1" />
        </div>
      </div>
      <div className="hm1_mo_hd_a">
        <img
          loading="lazy"
          src={kalyaniDark}
          className="hm1_mo_hd_b"
          onClick={() => (window.location = "/")}
          alt="KAlyani"
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="header_text"
            onClick={() =>
              window.location.assign("tel:" + (location.phone_number ?? ""))
            }
          >
            <img
              className="headphone_icon"
              alt="Img"
              src={phoneIcon}
              width="15"
            />
            {location != null ? location.phone_number : null}
          </div>
          <img alt="Maruthi Logo"  src={maruthiLogo2}  className="logo_icon1" />
        </div>
      </div>

{/* Google Script pasted by arul */}
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <script 
      async 
      src="https://www.googletagmanager.com/gtag/js?id=UA-33586910-1">
        
      </script>
      <script>
      {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
       gtag('config', 'UA-33586910-1');`}
      </script>
  {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}

  {/* <!-- Global site tag (gtag.js) - Google Ads -->  */}
      <script 
      async 
      src="https://www.googletagmanager.com/gtag/js?id=AW-1014248716">
      </script>
       <script> 
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'AW-1014248716');`} 
      </script>

  {/* <!-- Global site tag (gtag.js) - Google Ads -->  */}


  {/* <!-- Facebook Pixel Code --> */}
  <script>
   {` !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '539229427481212'); 
    fbq('track', 'PageView'); `}
  </script>
<noscript>
<img height="1" width="1" 
src="https://www.facebook.com/tr?id=539229427481212&ev=PageView
&noscript=1"/>
</noscript>
{/* <!-- End Facebook Pixel Code --> */}

  
{/* Google Script pasted by arul - END */}


    </React.StrictMode>
  );
}

export function HomeTop({ props }) {
  const { banners, location } = props.state;
  if (location === null) return "";
  const _banners =
    location.title.toLowerCase() === "hyderabad"
      ? banners.hyderabad
      : banners.bengaluru;

  return (
    <div className="hm1_a">
      <div className="hm1_b">
        <Carousel autoPlay showThumbs={false}>
          {_banners.map((b, k) => (
            <img
              key={k}
              alt="Its A Bannner"
              className="hm1_d"
              src={getImage(b.banner_img)}
            />
          ))}
        </Carousel>
      </div>

      {/* <div className="car_setting_popup">
        <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="car_setting_popup_title">
        Find Your Car or Car Service
        </div>
        </div>
        <form
        onChange={props.carAndServiceSearch}
        onSubmit={props.carAndServiceSearch}
        >
        <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="car_setting_popup_body">
        <select id="type" className="car_setting_popup_type_input">
        <option value="c">New Car</option>
        <option value="s">Service</option>
        </select>
        <div className="hm1_e">
        <input
        id="value"
        className="car_setting_popup_car_input"
        placeholder="SELECT YOUR CAR"
        autoComplete="off"
        />
        {homeSearchs.length !== 0 ? (
                  <div className="hm1_c">
                  {homeSearchs.map((hs, k) => (
                    <div
                    key={k}
                    className="hm1_f"
                    onClick={() => {
                      if (hs.title === null)
                      window.location =
                      "/car/" + makespaceline(hs.route ?? "");
                      else
                      window.location =
                      "/service/" + makespaceline(hs.title ?? "");
                    }}
                      >
                        {hs.name ?? hs.title}
                        </div>
                        ))}
                        </div>
                        ) : null}
                        </div>
              <button type="submit" className="car_setting_popup_button">
              Check Now
              </button>
              </div>
              </div>
        </form>
        <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="car_setting_popup_subtitle">
            <div className="row">Get instant quotes for your car service</div>
            <div style={{ cursor: "pointer" }} className="row">
              <div onClick={() => props.executeScroll("hm_c")}>
              Our Services
              </div>
              &nbsp;|&nbsp;
              <div onClick={() => props.executeScroll("hm_g")}>About Us</div>
            </div>
          </div>
          </div>
        </div> */}
    </div>
  );
}
