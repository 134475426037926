import axios from 'axios';

export async function getAllData(props) {
    const url = 'https://kalyanimotorsapi.kalyanicrm.com/api/';
    await Promise.all([
        axios.get(url + 'enquirybannerimg'), //  0
        axios.get(url + 'policybannerimg'), //  1
        axios.get(url + 'seasons'), //  2
        axios.get(url + 'commonfaq'), // 3
        axios.get(url + 'service'), //  4
        axios.get(url + (window.innerWidth > 576 ? 'banner' : 'mobilebanner')), //  5
        axios.get(url + 'iconlocation'), //  6
        axios.get(url + 'attachseasonservice'), //  7
        axios.get(url + 'blogpage'), //  8
    ])
        .then(function (res) {
            var locations_h = [];
            var locations_b = [];
            var locations_c = [];
            var locations_r = [];
            for (let i = 0; i < res[6].data.length; i++) {
                const element = res[6].data[i];
                if (element.city === 1) locations_b.push(element);
                else locations_h.push(element);
            }
            var location = localStorage.getItem('location');
            if (location === 'Hydrabad' && locations_h.length > 0)
                location = locations_h[0];
            if (location === 'Channapatna' && locations_c.length > 0)
                location = locations_h[0];
            if (location === 'Ramanagara' && locations_r.length > 0)
                location = locations_h[0];
            if (location === 'Bengaluru' && locations_b.length > 0)
                location = locations_h[0];

            var banners = { bengaluru: [], hyderabad: [] };

            for (let i = 0; i < res[5].data.length; i++) {
                const element = res[5].data[i];
                if (element.city === 'bengaluru')
                    banners.bengaluru.push(element);
                else banners.hyderabad.push(element);
            }

            props.setState({
                bannar_e: res[0].data[0].banner_img,
                bannar_p: res[1].data[0].policy_img,
                seasonCars: res[2].data,
                faq: res[3].data,
                services: res[4].data,
                banners,
                sesonService: res[7].data[0],
                dynamic_links: res[8].data,
                location,
                locations_h,
                locations_b,
            });
        })
        .catch(() => props.setState({ error: 'Not Fount' }));

    // await axios
    //   .get(" ")
    //   .then((res) => {
    //     if (res.data.city === "Bengaluru")
    //       props.setState({ location: props.state.locations_b[0] });
    //     if (res.data.city === "Hydrabad")
    //       props.setState({ location: props.state.locations_h[0] });
    //     // if (res.data.city === "Channapatna")
    //     //   props.setState({ location: props.state.locations_c[0] });
    //     // if (res.data.city === "Ramanagara")
    //     //   props.setState({ location: props.state.locations_r[0] });
    //   })
    //   .catch();

    await axios
        .get(url + 'modelcars')
        .then((res) => {
            var data_t = res.data;
            var data_t2 = res.data;

            for (let i = 0; i < data_t2.length; i++) {
                const element = data_t2[i];
                if (element.model.position != null)
                    data_t[element.model.position - 1] = element;
            }

            var datas = [
                { id: 'body', title: 'Body', cat: [] },
                { id: 'transmission', title: 'Transmission', cat: [] },
                // { id: "color_name", title: "Color", cat: [] },
                { id: 'fuel', title: 'Fuel', cat: [] },
            ];
            var found = null;
            for (let i = 0; i < data_t.length; i++) {
                found = null;
                for (let j = 0; j < datas[0].cat.length; j++)
                    if (datas[0].cat[j].title === data_t[i].body) {
                        found = j;
                        break;
                    }
                if (found !== null) datas[0].cat[found].cars.push(data_t[i]);
                else
                    datas[0].cat.push({
                        title: data_t[i].body,
                        cars: [data_t[i]],
                    });
                // //////////////////
                found = null;
                for (let j = 0; j < datas[1].cat.length; j++)
                    if (datas[1].cat[j].title === data_t[i].transmission) {
                        found = j;
                        break;
                    }
                if (found !== null) datas[1].cat[found].cars.push(data_t[i]);
                else
                    datas[1].cat.push({
                        title: data_t[i].transmission,
                        cars: [data_t[i]],
                    });
                // //////////////////
                found = null;
                for (let j = 0; j < datas[2].cat.length; j++)
                    if (datas[2].cat[j].title === data_t[i].fuel) {
                        found = j;
                        break;
                    }
                if (found !== null) datas[2].cat[found].cars.push(data_t[i]);
                else
                    datas[2].cat.push({
                        title: data_t[i].fuel,
                        cars: [data_t[i]],
                    });
            }

            for (let i = 0; i < datas.length; i++) {
                const e1 = datas[i].cat;
                var datas_f = [];
                for (let j = 0; j < e1.length; j++) {
                    const e2 = e1[j].title.split('/');
                    for (let k = 0; k < e2.length; k++) {
                        var found = false;
                        for (let l = 0; l < datas_f.length; l++) {
                            if (datas_f[l].title === e2[k]) {
                                found = true;
                                datas_f[l].cars = datas_f[l].cars.concat(
                                    datas[i].cat[j].cars
                                );
                            }
                        }
                        if (!found)
                            datas_f.push({
                                title: e2[k],
                                cars: datas[i].cat[j].cars,
                            });
                    }
                }
                datas[i].cat = datas_f;
            }

            props.setState({ findCars: datas });
        })
        .catch(() => props.setState({ error: 'Not Fount' }));
    props.setState({ loading: false });
    return 0;
}

var type = 'c';
export function homeCarAndServiceSearch(e, props) {
    e.preventDefault();
    if (e._reactName === 'onChange')
        if (e.target.id === 'type') type = e.target.type.value;
        else if (type === 'c') {
            var data = [];
            for (let i = 0; i < props.state.allCars.length; i++) {
                if (data.length === 5) break;
                const element = props.state.allCars[i];
                if (searchBYtext(element.name, e.target.value))
                    data.push(element);
            }
            props.setState({ homeSearchs: data });
        } else {
            data = [];
            for (let i = 0; i < props.state.services.length; i++) {
                if (data.length === 5) break;
                const element = props.state.services[i];
                if (searchBYtext(element.title, e.target.value))
                    data.push(element);
            }
            props.setState({ homeSearchs: data });
        }
    else {
    }
}

function searchBYtext(ss, s) {
    ss = ss.toUpperCase();
    s = s.toUpperCase();
    if (s.length < 3) return false;
    var ls = ss.split(s);
    if (ls.length < 2) return false;
    return true;
}
